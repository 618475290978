/**
 * Mixins to build quick media queries
 * 1. screen-up - min-width only
 * 2. screen-down - max-width only
 * 3. screen-between - between min and max-width
 * 
 */
.zone-small {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.zone-medium {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.zone-large {
  padding-top: 8rem;
  padding-bottom: 8rem;
}